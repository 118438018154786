import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { IAccountSummary } from 'src/app/_models';
import { AppConstants } from 'src/app/_common/shared/app.constants';

@Injectable({
  providedIn: 'root'
})
export class EmployerAccountApiService {
  private readonly THIRTY = 30;
  private readonly TERMINATED = 'Terminated';
  public accountSummary$: BehaviorSubject<IAccountSummary> = new BehaviorSubject<IAccountSummary>(null);
  public oktaUserSubAccountSummary$: BehaviorSubject<IAccountSummary> = new BehaviorSubject<IAccountSummary>(null);

  constructor(private http: HttpClient) { }

  public getAccountSummary(accountId: string, removeTerminated: boolean = true): Observable<IAccountSummary> {
    const accountSummaryUrl = `api/EmployerAccount/${accountId}/Summary`;
    const accounts$ = this.http.get<IAccountSummary>(accountSummaryUrl);
    const subaccountsList$ = this.getSubaccountsFromOkta();

    return combineLatest([accounts$, subaccountsList$]).pipe(
      map(([accntSummaryData, subaccountsList]) => {
        localStorage.setItem(AppConstants.USER_OKTA_ASSIGNED_SUBACCOUNTS, subaccountsList);
        if (accntSummaryData.accountStatus !== this.TERMINATED && removeTerminated) {
          accntSummaryData.subAccounts = this.checkForTerminatedAccountsInSummary(accntSummaryData.subAccounts);
        }
        this.accountSummary$.next(accntSummaryData);
        this.oktaUserSubAccountSummary$.next(this.updateSubAccountSummaryForUser(accntSummaryData, subaccountsList));
        return accntSummaryData;
      }, catchError(this.handleError)));
  }

  public checkForTerminatedAccountsInSummary(subAccounts: IAccountSummary[]): IAccountSummary[] {
    const result: IAccountSummary[] = [];
    if (subAccounts?.length > 0) {
      subAccounts.forEach(sa => {
        if (sa.accountStatus !== this.TERMINATED) {
          if (sa?.subAccounts?.length > 0) {
            sa.subAccounts = this.checkForTerminatedAccountsInSummary(sa.subAccounts);
          }
          result.push(sa);
        } else { // check/add terminated accounts within 30 days
          const today = new Date();
          const thirtyDaysAgo = formatDate((today.setDate(today.getDate() - this.THIRTY)), 'yyyy-MM-dd', 'en');
          if (sa?.accountTermedDate && Date.parse(sa.accountTermedDate) >= Date.parse(thirtyDaysAgo)) {
            result.push(sa);
          }
        }
      });
    }
    return result;
  }

  public getSubaccountsFromOkta(): Observable<string> {
    const username = JSON.parse(localStorage.getItem(AppConstants.USER_INFO_DETAILS))?.preferred_username;
    const subaccountsUrl = `api/OfficeUser/${username}/GetUserSubaccounts`;
    return this.http.get(subaccountsUrl, { responseType: 'text'}).pipe(catchError(this.handleError));
  }

  public updateSubAccountSummaryForUser(accountSummary: IAccountSummary, subaccountsList: string): IAccountSummary {
    if (!subaccountsList) {
      return accountSummary;
    }
    const revisedSubaccounts = this.revisedSubaccountSummaryTree(accountSummary?.subAccounts, subaccountsList?.split(','));
    return this.generateNewIAccountSummary(accountSummary, revisedSubaccounts);
  }

  public revisedSubaccountSummaryTree(subaccountsTree: Array<IAccountSummary>, subAccountsArray: Array<string>): Array<IAccountSummary> {
    const revisedSubaccountsTree: Array<IAccountSummary> = [];
    subaccountsTree?.forEach(
      (accountSummary: IAccountSummary) => {
        const accntSummaryAccountId: string = accountSummary?.accountId;
        subAccountsArray?.forEach(
          (subaccountId: string) => {
            if (accntSummaryAccountId.includes(subaccountId) && accntSummaryAccountId.length === subaccountId.length
                && !revisedSubaccountsTree.some( function(element) { return element.accountId === accntSummaryAccountId; })) {
              revisedSubaccountsTree.push(accountSummary);
            } else if (accountSummary.subAccounts?.length > 0) {
              const updatedSubsubaccountsTree = this.revisedSubaccountSummaryTree(accountSummary.subAccounts, subAccountsArray);
              if (updatedSubsubaccountsTree?.length > 0
                  && !revisedSubaccountsTree.some(function(element) { return element.accountId === accntSummaryAccountId; }) ) {
                const subaccountSummary: IAccountSummary = this.generateNewIAccountSummary(accountSummary, updatedSubsubaccountsTree);
                if (!revisedSubaccountsTree.includes(subaccountSummary)) {
                  revisedSubaccountsTree.push(subaccountSummary);
                }
              }
            }
          }
        );
      }
    );
    return revisedSubaccountsTree.length > 0 ? revisedSubaccountsTree : null;
  }

  private generateNewIAccountSummary(accountSummary: IAccountSummary, subaccountsSummary: IAccountSummary[]): IAccountSummary {
    return {
      accountName: accountSummary.accountName,
      accountId: accountSummary.accountId,
      shortAccountId: accountSummary.shortAccountId,
      subAccounts: subaccountsSummary
    } as IAccountSummary;
  }

  private handleError(error: any): Observable<never> {
    const errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(errMsg);
  }
}

export enum ViewState {
  addNewUser = 0,
  editUser,
  viewUser
}
