import { ISubscriberInfo } from './subscriber-info.interface';
import { IAddressInformation } from './contact-information.interface';
import { IBenefitSelection } from './benefit-selection.interface';
import { IDependent } from './dependent.interface';


export interface IEmployee  {
  hccSubscriberId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  location: string;
  socialSecurityNumber: string;
  maritalStatus: string;
  gender: string;
  accountNumber: string;
  subAccountNumber: string;
  employeeStatus: string;
  effectiveDate: string;
  amountBilled: string;
  amountAdjusted: string;
  adjustedReason: string;
  memberId: string;
  cancelDate: string;
  planName: string;
  planType?: 'Medical' | 'Dental' | 'BOTH';
  network: string;
  employeeId: string;
  medicalId: string;
  dentalId: string;
  subscriberIds: ISubscriberInfo[];
  contactInformation: IAddressInformation;
  benefitSelection: IBenefitSelection;
  dependents: IDependent[];
  expanded: boolean;
}
