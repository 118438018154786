export interface IEmployeeInfo {
    lastName: string;
    firstName: string;
    employeeId: string;
    location: string;
    dateOfBirth: string;
    gender: string;
    ssn: string;
    maritalStatus: string;
    race: string[];
    ethnicity: string[];
}

export type IMemberProfileEmployee = IEmployeeInfo & {
    hccSubscriberId: string;
    memberId: string;
    loggedInUserId: string;
    accountNumber: string;
    subAccountNumber: string;
};

export interface ISubmitEmployeeInfo {
  employeeInfo: IEmployeeInfo;
  shouldUpdateName: boolean;
  shouldUpdateSsn: boolean;
  shouldUpdateDateOfBirth: boolean;
  shouldUpdateMaritalStatus: boolean;
  shouldUpdateGender: boolean;
  shouldUpdateEmployeeLocation: boolean;
  shouldUpdateEmployeeId: boolean;
  shouldUpdateRace: boolean;
  shouldUpdateEthnicity: boolean;
}
