import { IBenefitSelection } from './benefit-selection';

export interface ITerminateCoverage {
    coverageTypes: string[];
    removalReason: string;
    termDate: string;
}

export type IMemberProfileTerminateCoverage = ITerminateCoverage & {
    hccSubscriberId: string;
    firstName: string;
    lastName: string;
    loggedInUserId: string;
    benefitSelection: IBenefitSelection;
    accountNumber: string;
    subAccountNumber: string;
};

