export interface IContactInfo {
  contactAddresses?: IContactAddress[];
  emailAddress: string;
  phoneNumbers: IPhoneNumber[];
}

export interface IContactAddress {
  addressType: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IPhoneNumber {
  phoneType: string;
  number: string;
}

export interface IContactInformation {
  address1?: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  emailAddress: string;
  phoneNumbers: IPhoneNumber[];
}

export type IMemberProfileContactInfo = IContactInfo & {
  hccSubscriberId: string;
  memberId: string;
  firstName: string;
  lastName: string;
  loggedInUserId: string;
  accountNumber: string;
  subAccountNumber: string;
};

export interface ISubmitContactInfo {
  contactInfo: IContactInfo;
  shouldUpdateAddress: boolean;
  shouldUpdatePhoneNumbers: boolean;
  shouldUpdateEmail: boolean;
}
