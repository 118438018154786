export interface IPlanSelection {
  planType: string;
  planName: string;
  benefit_Plan_Hcc_Id: string;
  deductible: string;
  copayment: string;
  coInsurance: string;
  retiree: boolean;
  cobra: boolean;
}
