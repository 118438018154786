import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { DashBoardComponent } from './landingpage/dashboard/dashboard.component';
import { DebuggingComponent } from './layout/debugging/debugging.component';
import { AccessDeniedComponent } from './landingpage/access-denied/access-denied.component';
import { AuthGuard } from './_common/auth/auth.guard';
import { HomeComponent } from './layout/home/home.component';
import { AppComponent } from './app.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { DashboardDataResolver } from './_common/resolvers/dashboard-data.resolver';
import { SitecoreItemResolver } from './_common/resolvers/sitecore-item.resolver';
import { MemberProfileDataResolver } from './_common/resolvers/member-profile-data.resolver';
import { NurseOnCallComponent } from './health-and-wellness/nurseOnCall/nurse-on-call.component';
import { SavedEmployeeDataResolver } from './_common/resolvers/saved-employee-data.resolver';

export const RESTRICTED_ROUTES: Routes = [
  {
    path: 'home',
    component: DashBoardComponent,
    canActivate: [AuthGuard],
    resolve: { sitecoreItem: SitecoreItemResolver },
    data: {
      sitecoreItem: { name: 'Dashboard' },
      onAuthRequired: onAuthRequired,
      expectedRole: ['Employer_ViewOnly', 'Employer_MemberManagement', 'Employer_OfficeManager', 'Employer_GBA', 'Employer_BillPay', 'Broker Portal Group', 'Employer_AZBeacon']
    }
  },
  {
    path: 'home/session',
    component: DashBoardComponent,
    canActivate: [AuthGuard],
    resolve: { sitecoreItem: SitecoreItemResolver },
    data: {
      sitecoreItem: { name: 'Dashboard' },
      expectedRole: ['Employer_ViewOnly', 'Employer_MemberManagement', 'Employer_OfficeManager', 'Employer_GBA', 'Employer_BillPay', 'Broker Portal Group', 'Employer_AZBeacon']
    }
  },
  { path: 'accessDenied', component: AccessDeniedComponent },
  {
    path: 'debug', component: DebuggingComponent,
    data: { expectedRole: ['Employer_ViewOnly', 'Employer_MemberManagement', 'Employer_OfficeManager', 'Employer_GBA', 'Employer_BillPay', 'Broker Portal Group', 'Employer_AZBeacon'] }
  },
  {
    path: 'employeeRoster',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_ViewOnly', 'Employer_GBA', 'Employer_MemberManagement', 'Employer_BillPay', 'Employer_OfficeManager', 'Broker Portal Group'] },
    loadChildren: () => import('./manage-my-group/employee-roster/employee-roster.module').then(m => m.EmployeeRosterModule)
  },
  {
    path: 'addEmployee',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_ViewOnly', 'Employer_GBA', 'Employer_MemberManagement', 'Employer_BillPay', 'Employer_OfficeManager', 'Broker Portal Group'] },
    loadChildren: () => import('./manage-my-group/add-employee/add-employee.module').then(m => m.AddEmployeeModule),
    resolve: { savedEmployeeApp: SavedEmployeeDataResolver }
  },
  {
    path: 'IDCard',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_ViewOnly', 'Employer_GBA', 'Employer_MemberManagement', 'Employer_BillPay', 'Employer_OfficeManager', 'Broker Portal Group', 'Employer_AZBeacon'] },
    loadChildren: () => import('./manage-my-group/id-card/id-card.module').then(m => m.IDCardModule),
  },
  {
    path: 'memberProfile/:subscriberId',
    loadChildren: () => import('./manage-my-group/member-profile/member-profile.module').then(m => m.MemberProfileModule),
    resolve: { memberProfile: MemberProfileDataResolver},
  },
  {
    path: 'eligibilityBenefits',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_ViewOnly', 'Employer_GBA', 'Employer_MemberManagement', 'Employer_BillPay', 'Employer_OfficeManager', 'Broker Portal Group', 'Employer_AZBeacon'] },
    loadChildren: () => import('./manage-my-group/eligibility-benefits/eligibility-benefits.module').then(m => m.EligibilityBenefitsModule)
  },
  {
    path: 'nurseOnCall',
    component: NurseOnCallComponent,
    data: { expectedRole: ['Employer_ViewOnly', 'Employer_MemberManagement', 'Employer_OfficeManager', 'Employer_GBA', 'Employer_BillPay', 'Broker Portal Group', 'Employer_AZBeacon'] }
  },
  {
    path: 'manageOfficeUserAccess',
    loadChildren: () => import('./oum/office-user-management.module').then(m => m.OfficeUserManagementModule),
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_GBA', 'Employer_OfficeManager', 'Employer_AZBeacon'] },
  },
  { path: 'implicit/callback', component: OktaCallbackComponent },
  {
    path: 'activityReport',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_ViewOnly', 'Employer_GBA', 'Employer_MemberManagement', 'Employer_BillPay', 'Employer_OfficeManager', 'Broker Portal Group', 'Employer_AZBeacon'] },
    loadChildren: () => import('./activity-report/activity-report.module').then(m => m.ActivityReportModule)
  },
  {
    path: 'savedApplications',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_MemberManagement', 'Employer_OfficeManager', 'Employer_GBA', 'Broker Portal Group']},
    loadChildren: () => import('./manage-my-group/saved-applications/saved-applications.module').then(m => m.SavedApplicationsModule)
  },
  {
    path: 'benefitBooks',
    canActivate: [AuthGuard],
    data: { onAuthRequired: onAuthRequired, expectedRole: ['Employer_ViewOnly', 'Employer_GBA', 'Employer_MemberManagement', 'Employer_BillPay', 'Employer_OfficeManager', 'Broker Portal Group', 'Employer_AZBeacon']},
    loadChildren: () => import('./benefit-documents/benefit-books/benefit-books.module').then(m => m.BenefitBooksModule)
  },
];

export function onAuthRequired(okatAuth, injector) {
  const router = injector.get(Router);
  router.navigate(['/log-in']);
}

export const UNRESTRICTED_ROUTES: Routes = [
  { path: 'log-in', loadChildren: () => import('./log-in/log-in.module').then(m => m.LogInModule) },
  {
    path: 'redirect-log-in', loadChildren: () => import('./redirect-log-in/redirect-log-in-routing.module').then(
      m => m.RedirectLogInRoutingModule)
  },
  {
    path: 'redirect-log-in/azbeacon', loadChildren: () => import('./redirect-log-in/azbeacon/azbeacon-routing.module').then(
      m => m.AZBeaconRoutingModule)
  },
  {
    path: 'redirect-log-in/early-access-azbeacon', loadChildren: () => import('./redirect-log-in/early-access-azbeacon/early-access-azbeacon-routing.module').then(
      m => m.EarlyAccessAZBeaconRoutingModule)
  },
  {
    path: 'redirect-log-in/early-access-hrp', loadChildren: () => import('./redirect-log-in/early-access-hrp/early-access-hrp-routing.module').then(
      m => m.EarlyAccessHRPRoutingModule)
  }
];

const routes: Routes = [
  { path: '', redirectTo: 'log-in', pathMatch: 'full' },
  {
    path: '', component: HomeComponent, children: RESTRICTED_ROUTES,
    resolve: { dashboardData: DashboardDataResolver }
  },
  {
    path: '', component: AppComponent, children: UNRESTRICTED_ROUTES
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRoutingModule {
}
