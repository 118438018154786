import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class DebuggingService {
    public orderIdCard = {
        addressChangeFailure: false,
        mockImageCall: false,
        includedMockCards: {
            medical: false,
            dental: false
        },
        simulateOrderIdCardRequestFailure: false
    };

    public officeUserManagement = {
        addUser: {
            simulateApiCallFail: false,
            simulateInvalidEmail: false
        },
        editUser: {
            simulateApiCallFail: false
        },
        deleteUser: {
            simulateApiCallFail: false
        }
    };

    public eligibilityAndBenefits = {
        searchUser: {
            simulateApiCallFailInSearch: false
        }
    };

    public memberProfile = {
        submitMember: {
            simulateApiCallFail : false
        }
    };

    public standardApplication = {
        submitEmployee: {
            simulateApiCallFail : false
        },
        saveEmployeeApp: {
            simulateApiCallFail : false
        }
    };

    public savedApplications = {
            simulateNoSavedAppFound : false
    };

    public activityReport = {
        simulateNoEmployeeInfoFound : false
    };

    public employeeRoster = {
        noEmployeeFoundPage : false
    };

    public benefitBooks = {
        noBenefitBooksFoundPage : false
    };
}
