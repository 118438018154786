import { IBenefitSelection } from './benefit-selection.interface';

export interface IDependent {
  firstName: string;
  middleName: string;
  lastName: string;
  dependentType: string;
  dateOfBirth: string;
  effectiveDate?: string;
  ssn: string;
  gender: string;
  memberId: string;
  cancelDate: string;
  eligibleForMedicare: boolean;
  benefitSelection: IBenefitSelection;
  removalReason: string;
}
