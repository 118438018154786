import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GenderService {
    public gender: Array<IGender> = [{ name: 'Male', alpha: 'M' }, { name: 'Female', alpha: 'F' }];

    public getValidGender(value: string): IGender {
        return this.gender.find((g: IGender) => g.name.toLowerCase() === value.toLowerCase()
        || g.alpha.toLowerCase() === value.toLowerCase());
    }
}

export interface IGender {
    name: string;
    alpha: string;
}
