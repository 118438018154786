import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, race, Subscription } from 'rxjs';
import { ManageMyGroupDropdownService } from 'src/app/manage-my-group/_common/services/manage-my-group-dropdown.service';
import { UserInfoService } from 'src/app/_services/user-info-service/userInfo.service';
import {
  IBenefitSelection,
  IContactInfo,
  IEmployeeForm, IEmployeeInfo as EmployeeDataForm, IPlanInfo, MedicareCoverage,
  IDependentInfo
} from '../../_common/models';
import { IAddressInfo } from '../../_common/models/submit-to-api/address-info';
import { IContactDataInfo } from '../../_common/models/submit-to-api/contact-info';
import { IEmployeeDataInfo } from '../../_common/models/submit-to-api/employee-data-info';
import { IEmployeeInfo } from '../../_common/models/submit-to-api/employee-info';
import { IMedicareDataInfo } from '../../_common/models/submit-to-api/medicare-Info';
import { IPhoneNumberDataInfo } from '../../_common/models/submit-to-api/phone-number-info';
import { IProductDataInfo } from '../../_common/models/submit-to-api/products-info';
import { ISubscriberDataInfo, ISubscriberDataInfoRel } from '../../_common/models/submit-to-api/subscriber-info';
import { SitecoreContentService } from 'src/app/_services/sitecore-content-service/sitecore-content.service';
import { GenderService } from 'src/app/_common/services/gender.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeInfoContentService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private accountId: string;
  private today: Date = new Date();
  private effectiveDateOfCoverage: Date;
  private healthPlansFromService: IPlanInfo[] = [];
  private dentalPlansFromService: IPlanInfo[] = [];
  private healthPlanId: string;
  private dentalPlanId: string;
  private cdhpToggle: boolean;
  private raceEthnicityToggle: boolean;

  constructor(
    private dropdownService: ManageMyGroupDropdownService,
    public userInfoService: UserInfoService,
    private sitecoreContentService: SitecoreContentService,
    private genderService: GenderService,
  ) {
    combineLatest([
      this.sitecoreContentService.getSiteCoreToggle('CdhpToggle'),
      this.sitecoreContentService.getSiteCoreToggle('EmployerPortal_AddRaceEthnicity_Toggle')
    ]).subscribe(([cdhpToggle, raceEthnicityToggle]) => {
      this.cdhpToggle = cdhpToggle;
      this.raceEthnicityToggle = raceEthnicityToggle;
    });
  }

  public parseToEmployeeDataInfo(employeeData: IEmployeeForm): IEmployeeDataInfo {
    this.getPlansFromService();

    const numberOfPeople = 1;
    this.accountId = employeeData?.employeeInfo?.subAccount;
    this.effectiveDateOfCoverage = new Date(employeeData.employeeInfo.effectiveDateOfCoverage);

    const employeeContent: IEmployeeDataInfo = {
      subscriber: this.raceEthnicityToggle ? this.parseToSubscriberRel(employeeData) : this.parseToSubscriber(employeeData),
      dependents: employeeData.dependentsInfo !== undefined
        ? this.raceEthnicityToggle ? this.parseToDependentInfoRel(employeeData) : this.parseToDependentInfo(employeeData)
        : null,
      employee: this.parseToBasicEmployeeInfo(employeeData.employeeInfo),
      contact: this.parseToContactInfo(employeeData?.contactInfo),
      receivedDate: this.today.toISOString().slice(0, 10),
      totalNumberOfPeople: employeeData.dependentsInfo !== undefined
        ? numberOfPeople + employeeData.dependentsInfo.length
        : numberOfPeople
    } as IEmployeeDataInfo;
    return employeeContent;
  }

  private getPlansFromService(): void {
    this.subscriptions.push(
      this.dropdownService.BenefitPlanItems$.
        subscribe((plans) => {
          if (plans) {
            this.healthPlansFromService = plans;
          }
        })
    );
    this.subscriptions.push(
      this.dropdownService.DentalPlanItems$.
        subscribe((plans) => {
          if (plans) {
            this.dentalPlansFromService = plans;
          }
        })
    );
  }

  private getPlanID(healthPlanName: string, dentalPlanName: string): void {
    this.healthPlanId = this.healthPlansFromService
      .filter(plan => plan.planDescription === healthPlanName)
      .map((data) => {
        return data.planId;
      })[0];

    this.dentalPlanId = this.dentalPlansFromService
      .filter(plan => plan.planDescription === dentalPlanName)
      .map((data) => {
        return data.planId;
      })[0];
  }

  public parseToContactInfo(contactInfo: IContactInfo): IContactDataInfo {
    const phoneNumbers: IPhoneNumberDataInfo[] = [];
    if (contactInfo?.primaryPhone !== undefined) {
      phoneNumbers.push({
        phoneType: 'Primary',
        number: contactInfo?.primaryPhone
      });
    }

    if (contactInfo?.secondaryPhone !== undefined) {
      phoneNumbers.push({
        phoneType: 'Secondary',
        number: contactInfo?.secondaryPhone
      });
    }

    return {
      addresses: [{
        addressType: 'Primary',
        address1: contactInfo?.address,
        address2: contactInfo?.addressTwo,
        city: contactInfo?.city,
        state: contactInfo?.state,
        zip: contactInfo?.zipCode,
      } as IAddressInfo],
      phoneNumbers: phoneNumbers.length > 0 ? phoneNumbers : null,
      email: contactInfo?.email
    } as IContactDataInfo;
  }

  public parseToMedicareInformationOfDependent(medicareCoverage: MedicareCoverage): IMedicareDataInfo {
    return {
      isEligible: medicareCoverage.hasMedicareCoverage,
      medicareNumber: medicareCoverage.medicareCardNumber,
      partAEffectiveDate: medicareCoverage?.partAEffectiveDate ?? '',
      partBEffectiveDate: medicareCoverage?.partBEffectiveDate ?? '',
    } as IMedicareDataInfo;
  }

  public parseToProductsOfDependent(
    subscriberBenefitSelection: IBenefitSelection,
    benefitSelection: IBenefitSelection): IProductDataInfo[] {
    this.getPlanID(subscriberBenefitSelection.healthPlan, subscriberBenefitSelection?.dentalPlan);
    const products: IProductDataInfo[] = [];
    if (!!benefitSelection?.healthPlan && benefitSelection?.healthPlan !== 'Waive Coverage' &&
      (benefitSelection?.healthPlan === 'Same as subscriber' && subscriberBenefitSelection?.healthPlan !== 'Waive Coverage')) {
      products.push({
        effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
        contractType: 'MED',
        product: this.healthPlanId,
        accountId: this.accountId,
        requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10)
      });
    }
    if (!!benefitSelection?.dentalPlan && benefitSelection?.dentalPlan !== 'Waive Coverage' &&
      (benefitSelection?.dentalPlan === 'Same as subscriber' && subscriberBenefitSelection?.dentalPlan !== 'Waive Coverage')) {
      products.push({
        effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
        contractType: 'DEN',
        product: this.dentalPlanId,
        accountId: this.accountId,
        requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10)
      });
    }
    return products.length > 0 ? products : null;
  }

  public parseToMedicareInformationFromForm(benefitSelection: IBenefitSelection): IMedicareDataInfo {
    return {
      isEligible: benefitSelection.medicareCoverage.hasMedicareCoverage,
      medicareNumber: benefitSelection?.medicareCoverage?.medicareCardNumber,
      partAEffectiveDate: benefitSelection?.medicareCoverage?.partAEffectiveDate !== null
        ? new Date(benefitSelection?.medicareCoverage?.partAEffectiveDate).toISOString().slice(0, 10)
        : '',
      partBEffectiveDate: benefitSelection?.medicareCoverage?.partBEffectiveDate !== null
        ? new Date(benefitSelection?.medicareCoverage?.partBEffectiveDate).toISOString().slice(0, 10)
        : '',
    } as IMedicareDataInfo;
  }

  public parseToProductsFromForm(benefitSelection: IBenefitSelection): IProductDataInfo[] {
    this.getPlanID(benefitSelection?.healthPlan, benefitSelection?.dentalPlan);
    const products: IProductDataInfo[] = [];

    if (!!benefitSelection?.healthPlan && benefitSelection?.healthPlan !== 'Waive Coverage') {
      products.push({
        effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
        contractType: 'MED',
        product: this.healthPlanId,
        cdhProduct: this.cdhpToggle ? benefitSelection.cdhProduct : '',
        accountId: this.accountId,
        requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
      });
    }
    if (!!benefitSelection?.dentalPlan && benefitSelection?.dentalPlan !== 'Waive Coverage') {
      products.push({
        effectiveDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
        contractType: 'DEN',
        product: this.dentalPlanId,
        accountId: this.accountId,
        requiredStartDate: this.effectiveDateOfCoverage.toISOString().slice(0, 10),
      });
    }
    return products.length > 0 ? products : null;
  }

  private parseToBasicEmployeeInfo(employeeData: EmployeeDataForm): IEmployeeInfo {
    return {
      groupNumber: employeeData.accountNumber,
      sectionNumber: employeeData.subAccount,
      employeeId: employeeData.employeeID,
      employmentLocation: employeeData.employeeLocation,
      isMarried: employeeData?.maritalStatus === 'Married' ? true : false,
      marriageDate: !!employeeData?.dateOfMarriage
        ? new Date(employeeData?.dateOfMarriage).toISOString().slice(0, 10)
        : null
    } as IEmployeeInfo;
  }

  private parseToSubscriber(employeeData: IEmployeeForm): ISubscriberDataInfo {
    const subscriberDataInfo = {
      isDependent: false,
      lastName: employeeData.employeeInfo?.lastName,
      middleName: employeeData.employeeInfo?.middleName ?? '',
      firstName: employeeData.employeeInfo?.firstName,
      dateOfBirth: new Date(employeeData.employeeInfo.dateOfBirth).toISOString().slice(0, 10),
      ssn: employeeData.employeeInfo?.socialSecurityNumber.replace(/-/g, ''),
      gender: this.getValidGender(employeeData.employeeInfo?.gender),
      medicareInformation: employeeData?.benefitSelection?.medicareCoverage?.hasMedicareCoverage === true
        ? this.parseToMedicareInformationFromForm(employeeData.benefitSelection)
        : null,
      products: this.parseToProductsFromForm(employeeData.benefitSelection),
      maritalStatus: employeeData?.employeeInfo?.maritalStatus ?? null,
      dependent: false,
      loggedInUserId: this.userInfoService.getLoggedInUserId(),
      accountNumber: employeeData.employeeInfo?.accountNumber,
      subaccountNumber: employeeData.employeeInfo?.subAccount
    } as ISubscriberDataInfo;

    return subscriberDataInfo;
  }

  private parseToSubscriberRel(employeeData: IEmployeeForm): ISubscriberDataInfoRel {
    const subscriberDataInfo = {
      isDependent: false,
      lastName: employeeData.employeeInfo?.lastName,
      middleName: employeeData.employeeInfo?.middleName ?? '',
      firstName: employeeData.employeeInfo?.firstName,
      dateOfBirth: new Date(employeeData.employeeInfo.dateOfBirth).toISOString().slice(0, 10),
      ssn: employeeData.employeeInfo?.socialSecurityNumber.replace(/-/g, ''),
      gender: this.getValidGender(employeeData.employeeInfo?.gender),
      medicareInformation: employeeData?.benefitSelection?.medicareCoverage?.hasMedicareCoverage === true
        ? this.parseToMedicareInformationFromForm(employeeData.benefitSelection)
        : null,
      products: this.parseToProductsFromForm(employeeData.benefitSelection),
      maritalStatus: employeeData?.employeeInfo?.maritalStatus ?? null,
      dependent: false,
      loggedInUserId: this.userInfoService.getLoggedInUserId(),
      accountNumber: employeeData.employeeInfo?.accountNumber,
      subaccountNumber: employeeData.employeeInfo?.subAccount
    } as ISubscriberDataInfoRel;

    if (this.raceEthnicityToggle) {
      subscriberDataInfo.raceOrEthnicity = this.parseRaceEthnicity(employeeData.employeeInfo?.race, employeeData.employeeInfo?.ethnicity);
    }

    return subscriberDataInfo;
  }

  public parseToDependentInfo(employeeData: IEmployeeForm): ISubscriberDataInfo[] {
    const dependentDataInfo: ISubscriberDataInfo[] = employeeData.dependentsInfo.map((dependent: IDependentInfo) => ({
      isDependent: true,
      dependentType: dependent.relation ?? null,
      lastName: dependent.lastName,
      middleName: dependent?.middleName ?? null,
      firstName: dependent.firstName,
      dateOfBirth: new Date(dependent?.dateOfBirth).toISOString().slice(0, 10),
      ssn: dependent?.ssn?.replace(/-/g, ''),
      gender: this.getValidGender(dependent?.gender),
      medicareInformation: dependent.benefitSelection.medicareCoverage.hasMedicareCoverage
        ? this.parseToMedicareInformationOfDependent(dependent.benefitSelection.medicareCoverage)
        : null,
      products: this.parseToProductsOfDependent(employeeData.benefitSelection, dependent.benefitSelection),
      maritalStatus: dependent?.relation === 'Spouse' ? 'Married' : 'Single',
      dependent: true
    }));

    return dependentDataInfo;
  }

  public parseToDependentInfoRel(employeeData: IEmployeeForm): ISubscriberDataInfoRel[] {
    const dependentDataInfo: ISubscriberDataInfoRel[] = employeeData.dependentsInfo.map((dependent: IDependentInfo) => ({
      isDependent: true,
      dependentType: dependent.relation ?? null,
      lastName: dependent.lastName,
      middleName: dependent?.middleName ?? null,
      firstName: dependent.firstName,
      dateOfBirth: new Date(dependent?.dateOfBirth).toISOString().slice(0, 10),
      ssn: dependent?.ssn?.replace(/-/g, ''),
      gender: this.getValidGender(dependent?.gender),
      raceOrEthnicity: this.parseRaceEthnicity(dependent.race, dependent.ethnicity),
      medicareInformation: dependent.benefitSelection.medicareCoverage.hasMedicareCoverage
        ? this.parseToMedicareInformationOfDependent(dependent.benefitSelection.medicareCoverage)
        : null,
      products: this.parseToProductsOfDependent(employeeData.benefitSelection, dependent.benefitSelection),
      maritalStatus: dependent?.relation === 'Spouse' ? 'Married' : 'Single',
      dependent: true
    }));

    return dependentDataInfo;
  }

  parseRaceEthnicity(races: string[], ethnicities: string[]): string[] {
    const raceEthnicity: string[] = [];
    const hasRaceValue = races && races.length > 0;
    const hasEthnicityValue = ethnicities && ethnicities.length > 0;

    if (!hasRaceValue && !hasEthnicityValue) {
      raceEthnicity.push('7');
    }

    if (hasRaceValue) {
      raceEthnicity.push(...races.filter(x => x.startsWith('R')));
    }

    if (hasEthnicityValue) {
      raceEthnicity.push(...ethnicities.filter(x => x.startsWith('E')));
    }

    return raceEthnicity;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getValidGender(currentGender: string): string {
    const gender = this.genderService.getValidGender(currentGender.trim());
    return gender.name;
  }
}
