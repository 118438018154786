import { IBenefitSelection, IMemberProfileBenefitSelection } from './benefit-selection';
import { IContactInfo } from './contact-info';

export interface IDependentInfo {
  lastName: string;
  middleName: string;
  firstName: string;
  dependentType: string;
  dateOfBirth: string;
  ssn: string;
  gender: string;
  race: string[];
  ethnicity: string[];
  benefitSelection: IBenefitSelection;
  mraApprovalDate: string;
  isAddDependent?: boolean;
  effectiveDate?: string;
}

export interface IMemberProfileDependentInfo {
  hccSubscriberId: string;
  firstName: string;
  lastName: string;
  contactInformation: IContactInfo;
  benefitSelection: IMemberProfileBenefitSelection;
  dependents: IDependentInfo[];
  loggedInUserId: string;
  accountNumber: string;
  subAccountNumber: string;
  effectiveDateOfPolicy: string;
  updateDependent: boolean;
}
