import { AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CachedObservable } from '../../shared/cached-observable';
import { ReactantEnabledProperty } from '../type-safe-form-group';


export interface ITypeSafeControlBuildConfig<T> {
    name?: string;
    formControl?: FormControl;
    validators?: ValidatorFn[];
    validationErrors?: CachedObservable<ValidationErrors>;
    asyncValidators?: AsyncValidatorFn[];
    enabled?: ReactantEnabledProperty;
    valueChanges?: (value: T) => void;
    updateOn?: 'change' | 'blur' | 'submit';
}
