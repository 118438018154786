import { IPhoneNumber } from './phone-number.interface';


export interface IAddressInformation {
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  emailAddress: string;
  phoneNumbers: IPhoneNumber[];
}
