import { IDependentInfo } from './dependent-info';
import { IPlanSelection } from './plan-selection';

export interface IBenefitSelection {
    healthPlan: string;
    healthPlanCovered: string;
    dentalPlan: string;
    dentalPlanCovered: string;
    medicareType?: string;
    medicareNumber?: string;
    partAEffectiveDate?: string;
    partBEffectiveDate?: string;
    planSelection: IPlanSelection[];
    eligibleForMedicare: boolean;
    hasFlexibleSpending: boolean;
    flexibleSpendingSelection: string;
    cobraDate: string;
    cobra: boolean;
    reasonForRemoval?: string;
    termEffectiveDate?: string;
    cdhProduct?: string;
    subAccountNumber?: string;
}

export type IMemberProfileBenefitSelection = IBenefitSelection & {
    hccSubscriberId: string;
    memberId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    ssn: string;
    gender: string;
    maritalStatus: string;
    dependents: IDependentInfo[];
    loggedInUserId: string;
    effectiveDateOfChange?: string;
    accountNumber: string;
};
